import styled from "styled-components";
import {media} from '../styles/MediaMixins';
import PropTypes from "prop-types";

const Row = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContent || 'space-between'};
    align-items: ${props => props.alignContent || 'center'};
    align-content: center;
    width: 100%;
    margin: 0 auto;
    ${props => props.forceWrap && 'flex-wrap: wrap'};
    ${props => media[props.breakpoint ? 'until_' + props.breakpoint : 'touch']`
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: ${props => (props.shortMaxWidth ? (props.shortMaxWidth / 16) + 'rem' : 'unset')}
    `};
    ${props => media[props.breakpoint || 'desktop']`max-width: ${props => (props.maxWidth || 1570) / 16}rem`};
`
Row.propTypes = {
    className: PropTypes.string,
    maxWidth: PropTypes.number,
    shortMaxWidth: PropTypes.number,
    breakpoint: PropTypes.oneOf(['desktop']),
    justifyContent: PropTypes.oneOf(['flex-start', 'center', 'space-around']),
    alignContent: PropTypes.oneOf(['flex-start', 'center', 'space-around', 'stretch', 'flex-end']),
    forceWrap: PropTypes.bool
}
export default Row