import {css} from 'styled-components'
import PropTypes from "prop-types";

const sizes = {
    fullhd: 1408,
    widescreen: 1216,
    desktop: 1024,
    tablet: 769,
    mobile: 768,
}

const device = {
    fullhd: `(min-width: ${sizes.fullhd / 16}rem)`,
    widescreen: `(min-width: ${sizes.widescreen / 16}rem)`,
    widescreen_only: `(min-width: ${sizes.widescreen / 16}rem) and (max-width: ${(sizes.fullhd - 1) / 16}rem)`,
    until_widescreen: `(max-width: ${(sizes.widescreen - 1) / 16}rem)`,
    desktop: `(min-width: ${sizes.desktop / 16}rem)`,
    desktop_only: `(min-width: ${sizes.desktop / 16}rem) and (max-width: ${(sizes.widescreen - 1) / 16}rem)`,
    until_desktop: `(max-width: ${(sizes.desktop - 1) / 16}rem)`,
    touch: `(max-width: ${(sizes.desktop - 1) / 16}rem)`,
    tablet: `(min-width: ${sizes.tablet / 16}rem)`,
    until_tablet: `(max-width: ${sizes.mobile / 16}rem)`,
    mobile: `(max-width: ${sizes.mobile / 16}rem)`,
};

// iterate through the sizes and create a media template
export const media = Object.keys(device).reduce((accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    accumulator[label] = (...args) => css`
    @media ${device[label]} {
      ${css(...args)};
    }
  `
    return accumulator
}, {})

export function fluidTypography(minFontSize, maxFontSize, minScreen = 768, maxScreen = 1408) {
    return `
        @media (max-width: ${minScreen}px) {
            font-size: ${minFontSize / 16}rem;
        }
        @media (min-width: ${maxScreen + 1}px) {
            font-size: ${maxFontSize / 16}rem;
        }
        @media (min-width: ${minScreen + 1}px) and (max-width: ${maxScreen}px){
            font-size: calc(${minFontSize / 16}rem + (${maxFontSize - minFontSize}) * ((100vw - ${minScreen}px) / (${maxScreen - minScreen})));
        }
    `
}

fluidTypography.propTypes = {
    minFontSize: PropTypes.number.isRequired,
    maxFontSize: PropTypes.number.isRequired,
    minSize: PropTypes.number,
    maxSize: PropTypes.number
}

export function fluidSizing(attr, minSize, maxSize, minScreen = 768, maxScreen = 1408) {
    return `
        @media (max-width: ${minScreen}px) {
            ${attr}: ${minSize / 16}rem;
        }
        @media (min-width: ${maxScreen + 1}px) {
            ${attr}: ${maxSize / 16}rem;
        }
        @media (min-width:  ${minScreen + 1}px) and (max-width: ${maxScreen}px){
            ${attr}: calc(${minSize / 16}rem + (${maxSize - minSize}) * ((100vw - ${minScreen}px) / (${maxScreen - minScreen})));
        }
    `
}

fluidSizing.propTypes = {
    attr: PropTypes.string.isRequired,
    minSize: PropTypes.number.isRequired,
    maxSize: PropTypes.number.isRequired,
    minScreen: PropTypes.number,
    maxScreen: PropTypes.number
}