import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import ShowToast from "./toast/ShowToast";
import Title from "./title/Title";
import Container from "./layouts/Container";
import RowItem from "./layouts/RowItem";
import Row from "./layouts/Row";
import styled from "styled-components";

const SubscribeTitle = styled(Title)`
    text-align: center;
    margin-bottom: 3rem;
`

export default forwardRef(({closeParentModal}, ref) => {
    const [botField, setBotField] = useState(undefined);

    const handleChange = (e) => {
        setBotField(e.target.value);
    }
    const formRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        if (!form.email.value || form.email.value.indexOf('@') === -1) {
            ShowToast('warning', 'Please provide valid email!');
            return;
        }
        const data = {
            'form-name': form.getAttribute('name'),
            'email': form.email.value
        };
        if (botField) {
            data['bot-field'] = botField;
            console.log("You are a bot!");
        }
        fetch('https://7mn3bzuvvi.execute-api.us-east-1.amazonaws.com/prod/blog/subscribe', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data),
            mode: "no-cors"
        }).then(() => {
            ShowToast('success', 'Thanks for subscribing!');
            form.reset();
            setBotField(undefined);
            closeParentModal && closeParentModal(false);
        }).catch((error) => {
            ShowToast('success', 'Failed to subscribe!');
        })
    }

    useImperativeHandle(ref, () => ({
        reset() {
            formRef.current.reset();
            setBotField(undefined);
        }
    }));

    return (
        <>
            <Container>
                <Row maxWidth={633}>
                    <RowItem maxWidth={633}>
                        <SubscribeTitle>Subscribe to Our Blog</SubscribeTitle>
                    </RowItem>
                </Row>
            </Container>

            <form ref={formRef} name={'SubscribeToBlog'} method={"post"} netlify-honeypot={"bot-field"} data-netlify={"true"}
                  onSubmit={handleSubmit} className={'has-generic-padding'}>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type={"hidden"} name={"form-name"} value={"SubscribeToBlog"}/>
                <p hidden={true}>
                    <label>
                        Don’t fill this out: <input name={"bot-field"} onChange={handleChange}/>
                    </label>
                </p>
                <Container>
                    <Row maxWidth={575}>
                        <RowItem maxWidth={575}>
                            <div className={'field'}>
                                <label className={'label'}>
                                    Email
                                    <div className={'control'}>
                                        <input required={true} name={'email'} className={'input is-medium has-margin-top-5'} type={'email'}
                                               placeholder={'Ex. example@gmail.com'}/>
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                    </Row>
                </Container>

                <Container paddingTop={7}>
                    <Row maxWidth={577}>
                        <RowItem maxWidth={577}>
                            <button type={'submit'} style={{width: '100%'}}
                                    className={'has-text-weight-medium button is-rounded has-margin-top-20 button-green has-padding-top-15 has-padding-bottom-15 is-full-height'}>
                                SEND
                            </button>
                        </RowItem>
                    </Row>
                </Container>
            </form>
        </>
    )
});