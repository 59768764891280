import styled from "styled-components";
import {media} from '../styles/MediaMixins'
import PropTypes from "prop-types";

const RowItem = styled.div`
    width: 100%;
    ${props => media[props.breakpoint || 'desktop']`
        max-width: ${props => (props.maxWidth || 1570) / 16}rem;
        min-width: ${props => (props.minWidth || 0) / 16}rem;
    `};
    ${props => media[props.breakpoint ? 'until_' + props.breakpoint : 'touch']`
        max-width: ${props => (props.shortMaxWidth ? (props.shortMaxWidth / 16) + 'rem' : 'unset')};
        min-width: ${props => (props.shortMinWidth ? (props.shortMinWidth / 16) + 'rem' : 'unset')};
    `};
`
RowItem.propTypes = {
    className: PropTypes.string,
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    shortMaxWidth: PropTypes.number,
    shortMinWidth: PropTypes.number,
    breakpoint: PropTypes.oneOf(['desktop'])
}
export default RowItem