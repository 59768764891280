import styled from "styled-components";
import PropTypes from "prop-types";
import {media} from "../styles/MediaMixins";

const Container = styled.section`
    min-height: ${props => props.minHeight ? (props.minHeight / 16 + 'rem') : 'unset'};
    display: flex;
    padding-right: ${50 / 16}rem;
    padding-left: ${50 / 16}rem;
    ${media.mobile`
        padding-right: ${28 / 16}rem;
        padding-left: ${28 / 16}rem;
    `};
    flex-direction: column;
    justify-content: center;
    align-items: ${props => props.justify || 'center'};
    align-content: center;
    ${props => props.forceWrap && 'flex-wrap: wrap'};
    padding-top: ${props => (props.paddingTop || 0) / 16}rem;
    padding-bottom: ${props => (props.paddingBottom || 0) / 16}rem;
`
Container.propTypes = {
    minHeight: PropTypes.number,
    className: PropTypes.string,
    breakpoint: PropTypes.string,
    paddingTop: PropTypes.number,
    paddingBottom: PropTypes.number,
    justify: PropTypes.string,
    forceWrap: PropTypes.bool
}
export default Container;