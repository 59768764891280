import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useLocation} from "@reach/router";

const SEO = ({htmLClass, title, description, meta_keyword, meta_image_width, meta_image_height, image, article, siteUrl}) => {
  const {pathname} = useLocation();

  const seo = {
    title: title,
    description: description,
    keyword: meta_keyword,
    image: image,
    image_width: meta_image_width,
    image_height: meta_image_height,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title}>
      <html className={htmLClass} lang={'en'}/>
      <meta name={"description"} content={seo.description}/>

      <meta name={"image"} content={seo.image}/>

      <meta name={"keywords"} content={seo.keyword}/>

      <meta name={'viewport'} content={'width=device-width, initial-scale=1.0,minimum-scale=1.0'}/>

      {seo.url && <meta property={"og:url"} content={seo.url}/>}

      {(article ? true : null) && <meta property={"og:type"} content={"article"}/>}

      {seo.title && <meta property={"og:title"} content={seo.title}/>}

      {seo.description && (
        <meta property={"og:description"} content={seo.description}/>
      )}

      {seo.image && <meta property={"og:image"} content={seo.image}/>}
      {seo.image && <meta property={"og:image:width"} content={seo.image_width}/>}
      {seo.image && <meta property={"og:image:height"} content={seo.image_height}/>}

      <link rel={"icon"} href={"/favicon.ico"} type={"image/x-icon"} />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}