import React from 'react'
import {toast} from "react-toastify";
import success from "../../img/success_logo.svg";
import warning from "../../img/warning_logo.svg";
import loading from "../../img/loading_progress.gif";
import error from "../../img/error_logo.svg";
import './toast.sass'

export default (level, message) => {
    let logo;
    switch (level) {
        case 'error':
            logo = error;
            break;
        case 'warning':
            logo = warning;
            break;
        case 'success':
            logo = success;
            break;
        case 'loading':
            logo = loading;
            break;
        default:
            logo = success;
    }
    return toast(
        <div>
            <img className={'toast-img'} src={logo} alt={`${level} icon`}/>
            <div className={'toast-text'}>{message}</div>
        </div>, {
            className: `toast-background ${level}`,
            autoClose: level === loading ? false : 3000
        }
    );
}