import React, {useRef} from 'react';
import DiscussIdeas from './DiscussIdeas';

import cn from 'classnames'
import './styles/modal.sass'
import closeBtn from '../img/dialog_close.svg'
import styled from "styled-components";
import {media} from "./styles/MediaMixins";

const FormContainer = styled.div`
    margin-top: ${5 / 16}rem !important;
    padding-top: 0;
    ${media.touch`
        margin-left: -25px;
        margin-right: -25px;
    `}
`

export default ({modalShow, setModalShow}) => {
    const form = useRef();
    return (
        <div className={cn('modal custom-modal-dialog', {'is-active': modalShow})}>
            <button onClick={() => {
                form.current.reset();
                setModalShow(false);
            }} className={'modal-background is-borderless'}
                    style={{backgroundColor: '#00000099', width: '100vw'}}/>
            <div className={'modal-card'}>
                <header className={'modal-card-head is-borderless is-paddingless'} style={{backgroundColor: 'transparent'}}>
                    <div className={'modal-card-title'}/>
                    <button style={{height: 60, minWidth: 64}} className={'button is-white is-paddingless'} onClick={() => {
                        form.current.reset();
                        setModalShow(false);
                    }} aria-label={'close'}>
                        <img style={{height: 30, width: 30}} src={closeBtn} alt={'close button'}/>
                    </button>
                </header>
                <FormContainer className={'modal-card-body'}>
                    <DiscussIdeas ref={form} closeParentModal={setModalShow} formClassName={"has-generic-padding"}/>
                </FormContainer>
            </div>
        </div>
    );
}
