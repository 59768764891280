import React, {useRef} from 'react';
import cn from 'classnames'
import SubscribeForm from "./SubscribeForm";
import closeBtn from "../img/dialog_close.svg";
import styled from "styled-components";
import {media} from "./styles/MediaMixins";

const CardTitle = styled.p`
    background-color: white;
    z-index: 2;
`

const CardHead = styled.header`
    background-color: transparent !important;
    border: none;
    padding-bottom: 0;
`

const Card = styled.div`
    border-radius: 0;
    box-sizing: border-box;
    border: 1px solid #979797;
    background-color: #FFFFFF;
    max-width: ${1090 / 16}rem;
    min-height: ${545 / 16}rem;
`

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
`

const FormContainer = styled.div`
    margin-top: ${5 / 16}rem !important;
    padding-top: 0;
    ${media.touch`
        margin-left: -25px;
        margin-right: -25px;
    `}
`

export default ({modalShow, setModalShow}) => {
    const form = useRef();
    return (
        <Wrapper className={cn('modal', {'is-active': modalShow})}>
            <button onClick={() => {
                form.current.reset();
                setModalShow(false);
            }} className={'modal-background is-borderless is-paddingless'}
                    style={{backgroundColor: '#00000099', width: '100vw'}}/>
            <Card className={'modal-card'}>
                <CardHead className={'modal-card-head'}>
                    <CardTitle className={'modal-card-title'}/>
                    <button style={{height: 60}} className={'button is-white'} onClick={() => {
                        form.current.reset();
                        setModalShow(false);
                    }} aria-label={'close'}>
                        <img src={closeBtn} alt={'close button'}/>
                    </button>
                </CardHead>
                <FormContainer className={'modal-card-body has-padding-top-5'}>
                    <SubscribeForm ref={form} closeParentModal={setModalShow}/>
                </FormContainer>
            </Card>
        </Wrapper>
    );
}
