import React, {memo, useLayoutEffect, useState} from 'react';
import "./navbar.sass"
import cn from 'classnames'
import Brand from './Brand';
import Divider from './Divider';
import NavLink from './NavLink';
import Dropdown from './Dropdown';
import styled from "styled-components";

const NavbarLeft = styled.div`
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  @media (min-width: 1024px) {
    padding-right: 15px;
    max-width: ${600 / 16}rem;
    width: 70%;
  };
`
const NavbarRight = styled.div`
  justify-content: space-between;
  @media (min-width: 1024px) {
    max-width: 17rem;
    width: 30%;
    height: 100%;
    justify-content: flex-end;
  };
  width: 100%;
  height: 30%;
  margin-left: auto;
  min-width: 0;
`
const NavbarBody = styled.div`
    @media (min-width: 1024px) {
      justify-content: space-between;
    }
    justify-content: flex-start;
    width: 78.83%;
`

const NavbarNav = styled.nav`
    max-width: 1570px;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: transparent;
`

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 3.125rem;
  padding-left: 3.125rem;
  @media (max-width: 768px) {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
`

const Estimate = styled.button`
    font-weight: 500;
    @media (max-width: 1024px) {
        font-size: 0.6875rem;
        height: 2.25rem;
    }
    @media (min-width: 1821px) {
        font-size: 0.78125rem;
        height: 2.6875rem;
    }
    @media (min-width: 1025px) and (max-width: 1820px){
        font-size: calc(0.6875rem + 1.5 * (100vw - 1024px) / 796);
        height: calc(2.25rem + 7 * (100vw - 1024px) / 796);
    }
`

const SocialMedia = styled.div`
    display: flex;
    flex-direction: row;
`

export default memo(({serviceIsActive, showSubscribe, hasBackgroundColor, setModalShow, setSubscribeModalShow, showMenu, setShowMenu}) => {
  const [shrink, setShrink] = useState(false);
  const [shortNav, setShortNav] = useState(false);
  const [logo, setLogo] = useState(1);

  const handleScroll = () => {
    setShortNav(window.innerWidth < 1024);
    if (window.scrollY > (window.innerWidth < 1024 ? 30 : 110)) {
      setShrink(true);
      setLogo(2);

    } else {
      setShrink(false);
      setLogo(1);
    }
  };

  useLayoutEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
      window.removeEventListener('scroll', handleScroll)
    };
  });
  return (
    <NavbarContainer className={
      cn('navbar',
        {'navbar-has-background-color': hasBackgroundColor},
        {'touch-screen-nav': shortNav},
        'is-fixed-top',
        'custom-navbar',
        'is-transparent',
        shrink ? 'shrink-navbar' : 'full-navbar'
      )}>
      <NavbarNav role={'navigation'} aria-label={'main navigation'} className={'navbar'}>

        <Brand logo={logo} showMenu={showMenu} setShowMenu={setShowMenu}/>

        <Divider shrink={shrink} shortNav={shortNav}/>

        <NavbarBody id={'cypress-navbar'} className={cn('navbar-menu', {'is-active': showMenu})}>
          <NavbarLeft className={'navbar-start'}>
            <NavLink shrink={shrink} shortNav={shortNav} to={'/'}>Homepage</NavLink>
            <NavLink shrink={shrink} shortNav={shortNav} to={'/about/'}>About Us</NavLink>
            <Dropdown shrink={shrink} shortNav={shortNav} serviceIsActive={serviceIsActive}/>
            <NavLink shrink={shrink} shortNav={shortNav} partiallyActive={true} to={'/portfolio/'}>Portfolio</NavLink>
            <NavLink shrink={shrink} shortNav={shortNav} to={'/blog/'}>Blog</NavLink>
            <NavLink shrink={shrink} shortNav={shortNav} to={'/contact/'}>Contact</NavLink>
          </NavbarLeft>

          <NavbarRight className={'navbar-end'}>
            {showSubscribe &&
            <div className={'navbar-item'}>
              <button
                className={cn('button is-rounded', 'menu-button', shrink && !shortNav ? 'button-green' : 'button-white')}
                onClick={() => setSubscribeModalShow(true)}>
                SUBSCRIBE
              </button>
            </div>}
            {!showSubscribe &&
            <div className={'navbar-item'}>
              <Estimate
                className={cn('button is-rounded', 'menu-button', shrink && !shortNav ? 'button-green' : 'button-white')}
                onClick={() => setModalShow(true)}>
                ESTIMATE PROJECT
              </Estimate>
            </div>}
            <SocialMedia>
              <NavLink type={'external'} to={'https://www.facebook.com/cypressoft/'}>
                <svg width={"16px"} height={"16px"} viewBox={"0 0 16 16"}>
                  <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
                    <g transform={"translate(-1678.000000, -29.000000)"}
                       fill={shrink && !shortNav ? '#888888' : '#FFFFFF'}>
                      <path
                        d={"M1693.39284,29 L1678.65181,29 C1678.30084,29 1678,29.3 1678,29.65 L1678,44.3 C1678,44.7 1678.30084,45 1678.65181,45 L1686.52372,45 L1686.52372,39 L1684.51814,39 L1684.51814,36.5 L1686.52372,36.5 L1686.52372,34.5 C1686.52372,32.45 1687.82735,31.4 1689.68251,31.4 C1690.58502,31.4 1691.33712,31.5 1691.53768,31.5 L1691.53768,33.65 L1690.23405,33.65 C1689.23126,33.65 1688.98056,34.15 1688.98056,34.85 L1688.98056,36.5 L1691.48754,36.5 L1690.98614,39 L1688.98056,39 L1689.0307,45 L1693.3427,45 C1693.69368,45 1693.99451,44.7 1693.99451,44.35 L1693.99451,29.65 C1694.04465,29.3 1693.74382,29 1693.39284,29 Z"}/>
                    </g>
                  </g>
                </svg>
              </NavLink>
              <NavLink type={'external'} to={'https://www.linkedin.com/company/the-cypress/'}>
                <svg width={"16px"} height={"16px"} viewBox={"0 0 16 16"}>
                  <g stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
                    <g transform={"translate(-1724.000000, -29.000000)"}
                       fill={shrink && !shortNav ? '#888888' : '#FFFFFF'}>
                      <g transform={"translate(1724.000000, 29.000000)"}>
                        <path
                          d={"M14.4,0 L1.6,0 C0.72,0 0,0.72 0,1.6 L0,14.4 C0,15.28 0.72,16 1.6,16 L14.4,16 C15.28,16 16,15.28 16,14.4 L16,1.6 C16,0.72 15.28,0 14.4,0 Z M4,13 L2,13 L2,6 L4,6 L4,13 Z M3.5,5 C2.66666667,5 2,4.33333333 2,3.5 C2,2.66666667 2.66666667,2 3.5,2 C4.33333333,2 5,2.66666667 5,3.5 C5,4.33333333 4.33333333,5 3.5,5 Z M13,13 L10.6666667,13 L10.6666667,8.9673913 C10.6666667,8.35872549 10.1222222,7.82608696 9.5,7.82608696 C8.87777778,7.82608696 8.33333333,8.35872549 8.33333333,8.9673913 L8.33333333,13 L6,13 L6,6.15217391 L8.33333333,6.15217391 L8.33333333,7.06521739 C8.72222222,6.45652174 9.57777778,6 10.2777778,6 C11.7555556,6 13,7.2173913 13,8.66304348 L13,13 Z"}/>
                      </g>
                    </g>
                  </g>
                </svg>
              </NavLink>
            </SocialMedia>
          </NavbarRight>
        </NavbarBody>
      </NavbarNav>
    </NavbarContainer>
  )
})