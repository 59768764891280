import React from 'react';
import cn from 'classnames';
import NavLink from './NavLink';
import styled from "styled-components";

const DropdownText = styled.div`
  display: flex;
  flex-direction: row !important;
  @media (max-width: 1023px) {
    display: none !important;
  }
`

export default ({shrink, shortNav, serviceIsActive}) => (
  <div className={'navbar-item has-dropdown is-hoverable'}>
    <DropdownText
      className={
        cn('navbar-link',
          'is-arrowless has-text-weight-medium',
          serviceIsActive ? (shrink && !shortNav ? 'shrink-active' : 'full-active') : '',
          'nav-link',
          shrink && !shortNav ? 'shrink-nav-link-hover' : 'full-nav-link-hover')
      }>
      Services
      <svg className={'has-margin-left-5'} width={"10px"} height={"6px"} viewBox={"0 0 10 6"}>
        <g stroke={"none"} strokeWidth="1" fill="none" fillRule={"evenodd"}>
          <g transform={"translate(-759.000000, -36.000000)"} fill={"#CCCCCC"}>
            <path
              d={"M766.784128,39.4950514 L762.258274,43.7947591 C761.970375,44.0684136 761.503596,44.0684136 761.215837,43.7947591 C760.928054,43.5213479 760.928054,43.0779014 761.215837,42.8045124 L765.220519,38.9999281 L761.215953,35.1954766 C760.928171,34.9219548 760.928171,34.4785525 761.215953,34.2051413 C761.503736,33.9316196 761.970491,33.9316196 762.25839,34.2051413 L766.784245,38.5049154 C766.928136,38.6416873 767,38.8207524 767,38.9999059 C767,39.179148 766.927996,39.3583459 766.784128,39.4950514 Z"}
              transform={"translate(764.000000, 39.000000) rotate(90.000000) translate(-764.000000, -39.000000)"}></path>
          </g>
        </g>
      </svg>
    </DropdownText>

    <div className={'navbar-dropdown'}>
      <NavLink shrink={!shortNav} state={{serviceIsActive: true}} to={'/services/mobile/'}>Mobile App
        Development</NavLink>
      <div className={'navbar-dropdown-hr'}/>
      <NavLink shrink={!shortNav} state={{serviceIsActive: true}} to={'/services/webdev/'}>Web Development</NavLink>
      <div className={'navbar-dropdown-hr'}/>
      <NavLink shrink={!shortNav} state={{serviceIsActive: true}} to={'/services/ux_design/'}>UI/UX
        Development</NavLink>
      <div className={'navbar-dropdown-hr'}/>
      <NavLink shrink={!shortNav} state={{serviceIsActive: true}} to={'/services/consulting/'}>Consulting Process with
        Cypress</NavLink>
    </div>
  </div>
)