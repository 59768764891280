import styled from "styled-components";
import {fluidSizing, fluidTypography} from '../styles/MediaMixins'

const Title = styled.h2`=
    font-size: ${60 / 16}rem;
    line-height: ${62 / 16}rem;
    ${fluidTypography(40, 60)};
    ${fluidSizing('line-height',45, 62)};
    color: #535B6C;
    font-weight: bold;
`

export default Title;