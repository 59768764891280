import React from 'react';
import {Link} from 'gatsby';
import cn from 'classnames';
import styled from 'styled-components';
import {fluidSizing, fluidTypography} from '../styles/MediaMixins';

const LinkA = styled.a`
    font-size: 1rem;
    width: auto;
    @media (max-width: 1024px) {
        padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    }
    @media (min-width: 1409px) {
        padding-left: 1.875rem;
    }
    @media (min-width: 1025px) and (max-width: 1408px){
        padding-left: calc(1.25rem + 10 * (100vw - 1024px) / 384);
    }
`
const LinkI = styled(Link)`
    ${fluidTypography(14, 16, 1024, 1408)};
    ${fluidSizing('padding-left', 5, 15, 1024, 1408)};
    ${fluidSizing('padding-right', 5, 15, 1024, 1408)};
`


export default ({type, shrink, shortNav, to, children, ...props}) => (
  type === 'external' ?
    <LinkA {...props}
           rel={'noopener noreferrer'}
           target={'_blank'}
           className={cn('navbar-item nav-link has-text-weight-medium', shrink && !shortNav ? 'shrink-nav-link-hover' : 'full-nav-link-hover')}
           href={to}>
      {children}
    </LinkA>
    :
    <LinkI {...props} activeClassName={shrink && !shortNav ? 'shrink-active' : 'full-active'}
           className={cn('navbar-item nav-link has-text-weight-medium', shrink && !shortNav ? 'shrink-nav-link-hover' : 'full-nav-link-hover')}
           to={to}>
      {children}
    </LinkI>
)