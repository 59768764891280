import React, {useLayoutEffect, useState} from 'react';
import Footer from '../components/Footer';
import CypNavBar from '../components/navbar/Navbar';
import useSiteMetadata from './SiteMetadata';
import {ToastContainer} from 'react-toastify';
import EstimateProjectModal from './EstimateProjectModal';
import SubscribeModal from './SubscribeModal';
import cn from 'classnames';
import styled from 'styled-components';
import {media} from './styles/MediaMixins';
import SEO from "./SEO";

const ToastContainerCustom = styled(ToastContainer)`
    ${media.desktop`
      max-width: ${450 / 16}rem;
      width: ${450 / 16}rem;
    `};
    max-width: unset;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
`

const TemplateWrapper = ({
                           local_title, meta_description, meta_keyword, children, meta_image, meta_image_width,
                           meta_image_height, serviceIsActive, showSubscribe, hasBackgroundColor, article
                         }) => {
  const {title, description, siteUrl} = useSiteMetadata();
  const [tabbing, setTabbing] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [subscribeModalShow, setSubscribeModalShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useLayoutEffect(() => {
    const disableTabbing = () => {
      setTabbing(true);
      window.removeEventListener('keydown', disableTabbing);
    }
    window.addEventListener('keydown', disableTabbing);
    return () => {
      window.removeEventListener('keydown', disableTabbing)
    };
  });
  return (
    <>
      <SEO
        htmLClass={cn({'user-is-tabbing': tabbing}, {'dialog-is-open': modalShow || subscribeModalShow || showMenu})}
        title={local_title || title}
        description={meta_description || description}
        meta_keyword={meta_keyword}
        image={meta_image ? `${siteUrl}${meta_image}` : `${siteUrl}/static/img/og-image.jpg`}
        meta_image_width={meta_image_width}
        meta_image_height={meta_image_height}
        siteUrl={siteUrl}
        article={article}
      />
      <CypNavBar
        setModalShow={setModalShow}
        setSubscribeModalShow={setSubscribeModalShow}
        serviceIsActive={serviceIsActive}
        showSubscribe={showSubscribe}
        hasBackgroundColor={hasBackgroundColor}
        showMenu={showMenu}
        pauseOnFocusLoss={false}
        setShowMenu={setShowMenu}/>
      <ToastContainerCustom
        position={'top-center'}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={true}
        progressStyle={{background: 'white'}}
        draggable
      />
      <>{children}</>
      <Footer/>
      {modalShow && <EstimateProjectModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />}
      {subscribeModalShow && <SubscribeModal
        modalShow={subscribeModalShow}
        setModalShow={setSubscribeModalShow}
      />}
    </>
  )
};

export default TemplateWrapper
