import React, {memo} from "react";
import Img from "gatsby-image";
import BackgroundImage from 'gatsby-background-image'
import './header.sass'
import styled from "styled-components";
import {fluidSizing, media} from "../styles/MediaMixins";

const Title = styled.h1`
    @media (max-width: 768px) {
        font-size: 2.5rem;
        line-height: 2.8125rem;
    }
    @media (min-width: 1921px) {
        font-size: 5rem;
        line-height: 5.625rem;
    }
    @media (min-width: 769px) and (max-width: 1920px){
        font-size: calc(2.5rem + 40 * (100vw - 768px) / 1152);
        line-height: calc(2.8125rem + 45 * (100vw - 768px) / 1152);
    }
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: ${14 / 16}rem;
`

const Paragraph = styled.div`
    font-size: ${16 / 16}rem;
    line-height: ${28 / 16}rem;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: ${20 / 16}rem;
    color: #FFFFFF;
    @media (max-width: 768px) {
        font-size: 0.75rem;
        line-height: ${17 / 16}rem;
    }
    @media (min-width: 1921px) {
        font-size: 1rem;
        line-height: ${28 / 16}rem;
    }
    @media (min-width: 769px) and (max-width: 1920px){
        font-size: calc(0.75rem + 4 * (100vw - 768px) / 1152);
        line-height: calc(${17 / 16}rem + (${28 - 17}) * (100vw - 768px) / 1152);
    }
`

const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    @media (max-width: 1023px) {
        flex-direction: column;
        justify-content: flex-start;
        margin: ${props => (props.no_image ? 'auto' : '0')} auto auto;
    }
    @media (min-width: 1024px) {
        margin: auto;
        max-width: 1570px;
        padding-top: ${80 / 16}rem;
    }
   
`

const TextItem = styled.div`
    max-width: ${props => (props.no_image ? 1024 / 16 : 654 / 16)}rem;
    height: 100%;
    ${media.touch`
        margin-top: ${100 / 16}rem;
        width: 100%;
        min-width: 100%;
    `};
    width: ${props => (props.no_image ? 100 : 40)}%;
`

const ImageItem = styled.div`
    height: 100%;
    ${media.touch`
        position: relative;
        left: 0;
        width: calc(100% + ${56 / 16}rem);
        max-width: ${1023 / 16}rem;
        margin-bottom: ${34 / 16}rem;
    `};
    ${media.desktop`
        width: 60%;
        ${props => props.no_right_padding && `& > div {${fluidSizing('margin-right', -50, -150, 1600, 1920)}}`};
        max-width: ${916 / 16}rem;
    `};
    @media (max-device-aspect-ratio: 2/3) {
        margin-top: ${50 / 16}rem;
        margin-bottom: ${50 / 16}rem;
    };
`

const HeaderContainerDiv = styled.div`
    display: flex;
    min-height: 100vh;
    @media (max-device-aspect-ratio: 2/3) {
        min-height: unset;
    };
    ${media.mobile`
        padding-right: ${28 / 16}rem !important;
        padding-left: ${28 / 16}rem !important;
    `};
    ${media.touch`
        flex-direction: column;
        justify-content: flex-start;
    `};
    
    padding-left: ${50 / 16}rem;
    padding-right: ${50 / 16}rem;
    ${props => props.backgroundColor ?
  `background-color: ${props.backgroundColor}` :
  `background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);`}
`

const HeaderContainer = ({title, subtitle, title_image, title_image_mobile, action, no_right_padding}) => {
  return (
    <Row no_right_padding={no_right_padding} no_image={!title_image}>
      <TextItem no_image={!title_image}>
        <Title>{title}</Title>
        <Paragraph>{subtitle}</Paragraph>
        <br/>
        {!!action && (action)}
      </TextItem>
      <ImageItem no_right_padding={no_right_padding}>
        {title_image && (
          title_image_mobile ?
            <Img imgStyle={{height: 'auto'}} alt={`Header of ${title}`} className={'header-image'} fluid={[
              title_image_mobile.childImageSharp.fluid,
              {
                ...title_image.childImageSharp.fluid,
                media: `(min-width: 1024px)`,
              }]}/>
            :
            <Img imgStyle={{height: 'auto'}} alt={`Header of ${title}`} className={'header-image'}
                 fluid={title_image.childImageSharp.fluid}/>
        )}
      </ImageItem>
    </Row>
  )
};

const StyledBackgroundSection = styled(BackgroundImage)`
  background-size: cover !important;
`

export default memo(({title, subtitle, title_image, title_image_mobile, action, backgroundColor, backgroundImage, no_right_padding}) => {
  const header = <HeaderContainer
    title={title}
    subtitle={subtitle}
    title_image={title_image}
    title_image_mobile={title_image_mobile}
    no_right_padding={no_right_padding}
    action={action}
  />;
  return (
    <>
      {backgroundImage ?
        <StyledBackgroundSection className={'header-container'} Tag={"div"}
                                 fluid={[`linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%)`, backgroundImage.childImageSharp.fluid]}>
          {header}
        </StyledBackgroundSection>
        :
        <HeaderContainerDiv backgroundColor={backgroundColor} no_right_padding={no_right_padding}>
          {header}
        </HeaderContainerDiv>
      }
    </>
  )
});