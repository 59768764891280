import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import Select from 'react-select';
import Dropzone from 'react-dropzone'
import ShowToast from "./toast/ShowToast";
import Title from "./title/Title";
import styled from "styled-components";
import close from '../img/clear_file_selection.svg'
import Row from "./layouts/Row";
import RowItem from "./layouts/RowItem";
import Container from "./layouts/Container";
import {toast} from "react-toastify";
import {media} from "./styles/MediaMixins";

const JoinUsTitle = styled(Title)`
    text-align: center;
`

const Subtitle = styled.p`
    text-align: center;
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    font-weight: bold;
    color: #33333366;
    margin-top: ${16 / 16}rem;
    margin-bottom: ${40 / 16}rem;
`

const DeleteFile = styled.button`
    height: ${25 / 16}rem;
    width: ${25 / 16}rem;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
`

const AttachFiles = styled.p`
    &:before {
        width: 100%;
        color: #22BC88;
        font-weight: bold;
        font-size: ${24 / 16}rem;
        line-height: ${48 / 16}rem;
        display: inline-block;
        ${media.touch`content: 'Attach your file \u00A0';`}
        ${media.desktop`content: 'Drag and Drop \u00A0';`}
    }
`

const DidAttachFiles = styled.p`
    width: 100%;
    color: #22BC88;
    font-weight: bold;
    font-size: ${24 / 16}rem;
    line-height: ${48 / 16}rem;
    display: inline-block;
`

const ExtraText = styled.p`
    color: #CCCCCC;
    font-weight: bold;
    line-height: ${48 / 16}rem;
    display: inline-block;
    ${media.touch`display: none`}
`

const SendButton = styled.button`
    text-align: center;
    font-weight: 700;
    margin-top: ${10 / 16}rem;
    cursor: pointer;
    padding-top: ${15 / 16}rem;
    padding-bottom: ${15 / 16}rem;

    border-radius: ${27.5 / 16}rem;
    
    ${media.desktop`
        &:hover {
            background: #FFFFFF none;
            color: #11998E;
            border: 2px solid #20bb87;
        }
    `};
    
    ${media.touch`
        &:active {
            background: #FFFFFF none;
            color: #11998E;
            border: 2px solid #20bb87;
        }
        &:hover:not(:active) {
            color: #FFFFFF;
        }
    `};

    border: 2px solid transparent;
    background-clip: padding-box !important;
    background: linear-gradient(315deg, #20BB87 0%, #11998E 100%);
    color: #FFFFFF;

    white-space: nowrap;
`

function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
        formData.append(key, data[key])
    }
    return formData
}

export default forwardRef(({formClassName, closeParentModal}, ref) => {
    const customStyles = {
        control: provided => ({
            ...provided,
            minHeight: "45px",
            maxHeight: "45px",
            borderRadius: 'unset'
        }),
        dropdownIndicator: provided => ({
            ...provided,
            height: "20px",
            width: "20px",
            padding: 0,
            margin: 'auto',
            color: '#979797'
        }),
        indicatorsContainer: provided => ({
            ...provided,
            height: "43px",
            width: "45px",
            backgroundColor: '#D8D8D8',
        }),
        clearIndicator: provided => ({
            ...provided
        }),
        menuList: (provided, state) => ({
            ...provided,
            height: '100%'
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '50%',
            left: '50%',
            borderRadius: 'unset'
        }),
        option: (provided, state) => ({
            ...provided,
            //style={{borderBottom: '1px solid #979797'}}
            color: "#535B6C",
            backgroundColor: state.isSelected ? '#D8D8D8' : 'transparent',
            paddingTop: 15,
            paddingBottom: 15,
        }),
    };

    const options = [
        {value: 'range_1', label: <p>$25,000+</p>},
        {value: 'range_2', label: <p>$50,000+</p>},
        {value: 'range_3', label: <p>$100,000+</p>},
        {value: 'range_4', label: <p>$300,000+</p>},
    ];

    const fileInputRef = useRef();
    const formRef = useRef();
    const [attachmentFile, setAttachmentFile] = useState(undefined);
    const [botField, setBotField] = useState(undefined);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const handleChange = (e) => {
        !e.target ?
            setSelectedOption(e) :
            setBotField(e.target.value);
    }

    const accept = (files) => {
        setAttachmentFile(files[0]);
    };

    const onChange = () => {
        if (fileInputRef.current &&
            fileInputRef.current.files &&
            fileInputRef.current.files[0]) {
            setAttachmentFile(fileInputRef.current.files[0]);
        } else {
            setAttachmentFile(undefined)
        }
    };
    useImperativeHandle(ref, () => ({
        reset() {
            formRef.current.reset();
            setAttachmentFile(undefined)
            setBotField(undefined);
            setSelectedOption(null);
        }
    }));

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        if (!form.email.value || form.email.value.indexOf('@') === -1) {
            ShowToast('warning', 'Please provide valid email address!');
            return;
        }
        if (!selectedOption) {
            ShowToast('warning', 'Please select a Budget Range');
            return;
        }
        const data = {
            'form-name': form.getAttribute('name'),
            'fullname': form.fullname.value,
            'email': form.email.value,
            'telorskype': form.telorskype.value,
            'budget': form.budget.value,
            'idea': form.idea.value,
        };
        if (botField) {
            data['bot-field'] = botField;
            console.log("You are a bot!");
        }
        if (attachmentFile) {
            if (attachmentFile.size > 5 * 1024 * 1024) {
                ShowToast('warning', 'Please select a file smaller than 5MB');
                return;
            }
            data['attachmentFile'] = attachmentFile;
        }
        const loadingId = ShowToast('loading', 'Submitting...');
        closeParentModal && closeParentModal();
        fetch('/', {
            method: 'POST',
            body: encode(data),
        }).then(() => {
            toast.dismiss(loadingId);
            form.reset();
            setAttachmentFile(undefined)
            setBotField(undefined);
            setSelectedOption(null);
            ShowToast('success', 'Thanks for submission!');
        }).catch((error) => {
            toast.dismiss(loadingId);
            ShowToast('error', 'Failed to submit!');
        })
    }
    const clearFile = (e) => {
        e.preventDefault();
        fileInputRef.current.value = null;
        setAttachmentFile(null);
    };
    return (
        <>
            <Container maxWidth={1033}>
                <RowItem maxWidth={1033}>
                    <JoinUsTitle>
                        Let’s Discuss Your Ideas!
                    </JoinUsTitle>
                </RowItem>
                <RowItem maxWidth={1033}>
                    <Subtitle>
                        Fill in the form and we’ll come back to you within 2 business days
                    </Subtitle>
                </RowItem>
            </Container>
            <form ref={formRef} className={formClassName} name={'DiscussIdeas'} method={"post"}
                  netlify-honeypot={"bot-field"}
                  data-netlify={"true"}
                  onSubmit={handleSubmit}>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type={"hidden"} name={"form-name"} value={"DiscussIdeas"}/>
                <p hidden={true}>
                    <label>
                        Don’t fill this out: <input name={"bot-field"} onChange={handleChange}/>
                    </label>
                </p>
                <Container>
                    <Row maxWidth={1033}>
                        <RowItem maxWidth={502}>
                            <div className={'field has-padding-top-10 has-margin-right-10-desktop-only'}>
                                <label className={'label has-text-weight-medium'}>
                                    Full Name
                                    <div className={'control has-padding-top-5'}>
                                        <input name={'fullname'} required={true} className={'input is-medium'}
                                               type={'text'} placeholder={'Ex. Natalie Greenwood'}/>
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                        <RowItem maxWidth={502}>
                            <div className={'field has-padding-top-10 has-margin-left-10-desktop-only'}>
                                <label className={'label has-text-weight-medium'}>
                                    Email
                                    <div className={'control has-padding-top-5'}>
                                        <input name={'email'} required={true} className={'input is-medium'}
                                               type={'email'} placeholder={'Ex. example@gmail.com'}/>
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                    </Row>
                    <Row maxWidth={1033}>
                        <RowItem maxWidth={502}>
                            <div className={'field has-padding-top-10 has-margin-right-10-desktop-only'}>
                                <label className={'label has-text-weight-medium'}>
                                    Company (optional)
                                    <div className={'control has-padding-top-5'}>
                                        <input name={'telorskype'} className={'input is-medium'} type={'text'}
                                               placeholder={'Ex. My Company'}/>
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                        <RowItem maxWidth={502}>
                            <div className={'field has-padding-top-10 has-margin-left-10-desktop-only'}>
                                <label className={'label has-text-weight-medium'}>
                                    Budget Range
                                    <div className={'control has-padding-top-5 has-text-weight-normal'}>
                                        <Select
                                            onChange={handleChange}
                                            value={selectedOption}
                                            isSearchable={false}
                                            name={'budget'}
                                            options={options}
                                            styles={customStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                    </Row>
                    <Row maxWidth={1033}>
                        <RowItem maxWidth={1033}>
                            <div className={'field has-padding-top-10'}>
                                <label className={'label has-text-weight-medium'}>
                                    Share Your Ideas
                                    <div className={'control has-padding-top-5'}>
                                    <textarea name={'idea'} required={true} className={'textarea'} rows={'6'}
                                              placeholder={'Description'}/>
                                    </div>
                                </label>
                            </div>
                        </RowItem>
                    </Row>

                    <Row maxWidth={1033}>
                        <RowItem maxWidth={1033}>
                            <Dropzone onDrop={accept} noClick={false}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} style={{border: '1px dashed #11998E'}}
                                         className={'has-text-centered has-margin-bottom-20 has-margin-top-10'}>
                                        <label className={'label flex-row'}
                                               style={{justifyContent: 'center', alignItems: 'center'}}>
                                            {attachmentFile ?
                                                <DidAttachFiles>{attachmentFile.name}</DidAttachFiles>
                                                :
                                                <AttachFiles/>}
                                            {!attachmentFile ?
                                                <ExtraText>Just drop your file here</ExtraText>
                                                :
                                                <DeleteFile onClick={clearFile}>
                                                    <img src={close} alt={'eliminate selected file'}/>
                                                </DeleteFile>}
                                            <div className={'control'}>
                                                <input {...getInputProps()} className={'file-input'} type={'file'}
                                                       ref={fileInputRef}
                                                       onChange={onChange} name={'attachmentFile'}/>
                                            </div>
                                        </label>
                                    </div>
                                )}
                            </Dropzone>
                        </RowItem>
                    </Row>

                    <Row maxWidth={1033}>
                        <RowItem maxWidth={1033}>
                            <SendButton type={'submit'} style={{width: '100%'}}>
                                SEND
                            </SendButton>
                        </RowItem>
                    </Row>
                </Container>
            </form>
        </>
    )
});
